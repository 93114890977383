import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import { rgba } from 'polished';

const FooterWrapper = styled.section`
  padding: 80px 0 55px;
  margin-top: 40px;
  background-color: rgb(246, 249, 252);
  @media (max-width: 480px) {
    padding: 60px 0 30px;
  }
  .copyrightClass {
    @media (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .copyrightMenu {
      @media (max-width: 1024px) {
        margin-top: 10px;
        margin-bottom: 10px;
        justify-content: left;
        align-items: left;
        margin-left: 0;
      }
      @media (max-width: 767px) {
        justify-content: left;
        align-items: left;
        margin-left: 0;
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }
    .copyrightText {
      @media (max-width: 1100px) {
        margin-left: 0;
      }
    }
  }
  .social__share > a {
    display: inline-block;
    padding-right: 15px;
    &.instagram {
      color: #C13584;
    }
    &.twitter {
      color: #55acee;
    }
    &:hover {
      color: ${themeGet('colors.primary')} !important;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const List = styled.ul``;

const ListItem = styled.li`
  a {
    color: rgba(52, 61, 72, 0.8);
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: #343d48;
    }
  }
`;

const Nav = styled.nav`
  a {
    color: ${rgba('#02073E', 0.8)};
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.6;
    transition: 0.3s ease 0s;
    padding-bottom: 12px;
    &:last-child {
      padding-bottom: 0px;
    }
    img {
      margin-right: 12px;
    }
    &:hover {
      color: ${themeGet('colors.primary')};
    }
  }
`;

export { List, ListItem, Nav };

export default FooterWrapper;
