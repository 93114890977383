import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import NavbarWrapper from 'common/components/Navbar';
import Drawer from 'common/components/Drawer';
import Button from 'common/components/Button';
import Logo from 'common/components/UIElements/Logo';
import HamburgMenu from 'common/components/HamburgMenu';
import ScrollSpyMenu from 'common/components/ScrollSpyMenu';
import { Container } from './navbar.style';
import { openModal, closeModal } from '@redq/reuse-modal';
import SearchPanel from '../SearchPanel';
import LoginModal from '../LoginModal';

import LogoImage from 'common/assets/image/app/logo.png';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import { DrawerContext } from 'common/contexts/DrawerContext';
import { IoShareOutline, IoFilmOutline } from 'react-icons/io5';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Default close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);
const CloseModalButtonAlt = () => (
  <Button
    className="modalCloseBtn alt"
    variant="fab"
    onClick={() => closeModal()}
    icon={<i className="flaticon-plus-symbol" />}
  />
);
const Navbar = ({ navbarStyle, logoStyle, buttonStyle }) => {
  const { state, dispatch } = useContext(DrawerContext);
  // Search modal handler
  const handleSearchModal = () => {
    openModal({
      config: {
        className: 'search-modal',
        disableDragging: true,
        width: '100%',
        height: '100%',
        animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
        animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
        transition: {
          mass: 1,
          tension: 180,
          friction: 26,
        },
      },
      component: SearchPanel,
      componentProps: {},
      closeComponent: CloseModalButtonAlt,
      closeOnClickOutside: false,
    });
  };

  const handleShare = (shareObject) => {
    console.log(shareObject);
    if(typeof window !== "undefined") {
      const navigator = window.navigator;
      if (navigator.share) {
        navigator
          .share(shareObject)
          .then(() => console.log('ShareService_Share Successful share'))
          .catch((error) => {
            console.log('ShareService_Share Error sharing', error);
          });
      } else {
        console.log('ShareService_Share share not supported');
        copyToClipboard(shareObject.url);
      }
    }
  };

  const copyToClipboard = (val)=> {
    const selBox = document.createElement('textarea');

    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();

    document.execCommand('copy');
    document.body.removeChild(selBox);

    // TODO: translate
    toast('Copied to clipboard successfully');
  }
  // Authentication modal handler
  const handleLoginModal = () => {
    openModal({
      config: {
        className: 'login-modal',
        disableDragging: true,
        width: '100%',
        height: '100%',
        animationFrom: { transform: 'translateY(100px)' }, // react-spring <Spring from={}> props value
        animationTo: { transform: 'translateY(0)' }, //  react-spring <Spring to={}> props value
        transition: {
          mass: 1,
          tension: 180,
          friction: 26,
        },
      },
      component: LoginModal,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: false,
    });
  };
  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  const Data = useStaticQuery(graphql`
    query {
      appJson {
        menuItems {
          label
          path
          offset
        }
      }
    }
  `);

  const openApp = () => {
    window.location.href = 'https://app.shoffin.com/';
  };

  return (
    <>
    <NavbarWrapper {...navbarStyle}>
      <Container>
        <Logo
          href="/"
          logoSrc={LogoImage}
          title="Shoffin"
          logoStyle={logoStyle}
          isSVGLogo
        />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            variant="textButton"
            onClick={()=>handleShare({ title: 'Shoffin', text: 'Shoffin | Movies manager', url: 'https://shoffin.com/' })}
            icon={<IoShareOutline size={30} />}
            aria-label="share"
          />
          {/* <Button
            variant="textButton"
            onClick={() => openApp()}
            icon={<IoFilmOutline size={30} />}
            aria-label="search"
          /> */}
          {/* <Button
            variant="textButton"
            onClick={handleSearchModal}
            icon={<i className="flaticon-magnifying-glass" />}
            aria-label="search"
          />
          <Button
            variant="textButton"
            onClick={handleLoginModal}
            icon={<i className="flaticon-user" />}
            aria-label="login"
          />
          <Drawer
            width="420px"
            placement="right"
            drawerHandler={<HamburgMenu />}
            open={state.isOpen}
            toggleHandler={toggleHandler}
          >
            <ScrollSpyMenu
              menuItems={Data.appJson.menuItems}
              drawerClose={true}
              offset={-100}
            />
          </Drawer> */}
          {/* <Button variant="textButton" title="Open the App" onClick={() => openApp()} className="trail" /> */}
        </div>
      </Container>
    </NavbarWrapper>
    <ToastContainer />
    </>
  );
};

// Navbar style props
Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
  wrapperStyle2: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
  },
  logoStyle: {
    width: ['100px', '140px'],
  },
  buttonStyle: {
    minHeight: '70px',
    color: '#fff',
  },
};

export default Navbar;
